import React from "react"
import "../styles/global.scss"
import { Link } from "gatsby"
import { useState } from "react"
const Contact = () => {

const [navpopConcerts, setNavpopConcerts] = useState(false)
  const hamburgSecondary = (
      <svg
        onClick={() => setNavpopConcerts(!navpopConcerts)}
        className="hamburgSecondary"
      >
        <path
          d="M0.0864258 25.6278H48.5864"
          stroke="#F7F7F7"
          stroke-width="3"
        />
        <path
          id="middle-hamburg"
          d="M0 2H48.5"
          stroke="#F7F7F7"
          stroke-width="3"
        />
        <path
          id="bottom-hamburg"
          d="M0 49H48.5"
          stroke="#F7F7F7"
          stroke-width="3"
        />
      </svg>
  )

  const popUpNav = (
    <div className="popUpList">
      <div className="popUpContainer">
        <Link onClick={() => setNavpopConcerts(!navpopConcerts)} className="navLink" to="/">
          Home
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          className="navLink"
          to="/Concerts"
        >
          Concerts{" "}
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          classname="navLink"
          to="/Portraits"
        >
          Portraits
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          classname="navLink"
          to="/Weddings"
        >
          Weddings
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          classname="navLink"
          to="/Travel"
        >
          Travel
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          to="/Contact"
          style={{ color: "#fbc89a" }}
        >
          Contact
        </Link>
      </div>
    </div>
  )
  const closeIcon = (
    <svg
      className="closeIcon"
      onClick={() => setNavpopConcerts(!navpopConcerts)}
      viewBox="0 0 50 50"
      width="50"
      height="25"
      overflow="visible"
      stroke-width="6"
      stroke-linecap="round"
    >
      <line x1="0" y1="0" x2="50" y2="50" />
      <line x1="50" y1="0" x2="0" y2="50" />
    </svg>
  )

  return (
    <div className="secondary-page">
      <div className="secondary-top-section">
        <h1>Contact</h1>
      </div>

      <div className="box">
        <form
          action="https://getform.io/f/50ac3bab-049c-4c4f-b26c-3c70849b63b5"
          method="POST"
        >
          <div className="name">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              maxLength="50"
              size="20"
              required="required"
            />
          </div>
          <div className="email">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              maxLength="50"
              size="30"
              required="required"
            />
          </div>

          <div className="message">
            <label htmlFor="message">Message</label>
            <textarea
              type="text"
              name="message"
              id="message"
              rows="4"
              cols="50"
              required="required"
            ></textarea>
          </div>
          <div className="submitBtn">
            <button type="submit">Submit</button>
          </div>
        </form>
      {navpopConcerts ? closeIcon : hamburgSecondary}
      {navpopConcerts ? popUpNav : ""}
      </div>
      
    </div>
  )
}

export default Contact
